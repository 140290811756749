import React from 'react';

import Layout from '../components/layout';

const BookNow = () => {
  var pageTitle = 'Book Now';

  return (
    <Layout title={pageTitle}>
      <h1>{pageTitle}</h1>
      <div className="">
        <p>
          We apologize for the change but think you will love the new system and
          find it much more user friendly whether on your computer or on the
          mobile app!
        </p>
        <p>
          We have tried to make the transition as smooth as possible for our
          customers. All accounts existing on our old system and any
          corresponding reservations will be transferred for you. If you have an
          account with our old system (Ginger) you will need to log in to our
          new system below to claim your account. Reservations can be made with
          the mobile app or in your browser.
        </p>

        <p>
          Thank you so much for your help! We look forward to seeing you and
          your pet soon!
        </p>
        <p className="ml-5">
          &mdash; Laura, Tiana and the staff and family at Very Important Pets
        </p>
      </div>

      <hr className="my-3 mx-auto w-3/4 border-vip-beige" />

      <h2 className="text-center">Make a Reservation</h2>
      <p className="text-center">
        Our reservation system allows you to make grooming appointments or
        boarding reservations for your dog, cat or small animal. You will need
        to upload vaccination records so be sure to have those ready! You can
        also fill out waivers or make changes to existing reservations and can
        receive appointment/reservation reminders.
      </p>
      <p className="text-center">
        If able, please create an account and request a reservation with one of
        the following options:
      </p>

      <div className="flex justify-center gap-3 mb-3">
        <a
          href=" https://pawpartner.com/pz/book?p=756153"
          target="_blank"
          rel="noreferrer"
          className="btn btn-dark"
        >
          Website
        </a>
        <a
          href="https://apps.apple.com/ca/app/paw-partner/id1470136560"
          target="_blank"
          rel="noreferrer"
          className="btn btn-light"
        >
          Apple App Store
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.pawzlove.android"
          target="_blank"
          rel="noreferrer"
          className="btn btn-dark"
        >
          Google Play Store
        </a>
      </div>

      <p className="text-center">
        We realize that not all of our pet parents are comfortable with/have
        access to a computer. If you are not able to make your own account we
        would be happy to help you out. Please call 403-740-4833 and we will
        create an account for you over the phone.
      </p>

      {/* <h3 className="text-center">
        We are currently transitioning to using Gingr for our scheduling.
      </h3>
      <p className="text-center">
        To make an appointment or reservation please visit our portal.
      </p>
      <div className="flex justify-center gap-3 mb-3">
        <a
          href="https://veryimportantpet.portal.gingrapp.com/#/public/new_customer"
          target="_blank"
          rel="noreferrer"
          className="btn btn-dark"
        >
          Sign Up
        </a>
        <a
          href="https://veryimportantpet.portal.gingrapp.com/#/public/login"
          target="_blank"
          rel="noreferrer"
          className="btn btn-light"
        >
          Login
        </a>
      </div>
      <p className="text-center">
        You may also optionally download the app (scan or click below) and get
        alerts on your bookings.
      </p>
      <div className="flex justify-center">
        <a href="https://l.ead.me/bd4R8t" target="_blank" rel="noreferrer">
          <img
            width="200"
            src="/gingr_app_qr.png"
            alt="App Download QR Code"
          ></img>
        </a>
      </div> */}
    </Layout>
  );
};

export default BookNow;
